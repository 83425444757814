<template>
  <b-modal
    v-model="editShowModal"
    ok-title="Confirmer"
    cancel-title="Annuler"
    @ok="confimrEdit"
    title="Modification rapide"
  >
    <b-row class="my-1">
      <b-col md="3">
        <label for="input-small" style="font-weight: bold">Nom</label>
      </b-col>

      <b-col md="9">
        <b-form-input
          id="input-small"
          v-model="editName"
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>

    <b-row class="my-1">
      <b-col sm="3">
        <label for="input-small" style="font-weight: bold">Slug</label>
      </b-col>

      <b-col sm="9">
        <b-form-input
          id="input-small"
          v-model="editSlug"
          size="md"
        ></b-form-input>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BRow, BCol, BFormInput } from "bootstrap-vue";
export default {
  data() {
    return {
      editShowModal: this.showModal,
      editName: this.name,
      editSlug: this.slug,
    };
  },
  components: {
    BRow,
    BCol,
    BFormInput,
  },
  props: {
    showModal: Boolean,
    name: String,
    slug: String,
  },
  methods: {
    confimrEdit() {
      this.$emit("ConfirmEdit", { name: this.editName, slug: this.editSlug });
    },
  },
};
</script>
